// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA71SoYsogvHCQx5QYWKhGeslBRa7RqTRo",
  authDomain: "zuppi---clientes.firebaseapp.com",
  projectId: "zuppi---clientes",
  storageBucket: "zuppi---clientes.appspot.com",
  messagingSenderId: "72155548994",
  appId: "1:72155548994:web:1994e21ede245851038cdb",
  measurementId: "G-LL0WLS6RCQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


export const db = getFirestore(app)