import React from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';


const Obrigado = () => {
    return(
        <div className="obrigado">
            <div className="container">
                <div className="logo">
                    <img src="logo.svg" alt="" />
                </div>
                <br /><br />
                <div className="textoObrigado">
                    <h1>Obrigado por nos escolher</h1>
                    <h4>
                    Você está entrando na Nova Revolução e estamos felizes por tomar essa decisão!
                    </h4>

                </div>

                <Link to={'/'}>
                        <button className='botaoObrigado'>
                            Voltar
                        </button> 
                </Link>

 

                

            </div>
        </div>
    )
}

export default Obrigado;