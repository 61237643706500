import React, { Component, SyntheticEvent, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { db } from '../firebase-config'
import { collection, getDocs, addDoc } from 'firebase/firestore'

 class Register extends Component {

    email= '';
    celular = '';
    state= {
      redirect: false
    }
    
    
    render() {
      
      const usuariosCollectionRef = collection(db, "usuarios");

      const createUser = async (e: SyntheticEvent) => {
          e.preventDefault(); 
          await addDoc(usuariosCollectionRef, {
              email: this.email,
              celular: this.celular
          })

          this.setState({
            redirect: true
          })
      }

      if (this.state.redirect) {
        return <Navigate replace to={'/obrigado'} />
      }
         
    return (
        <div className='container'>
      
        <div className="row">
  
          <div className="col-lg-6">
  
          <div className="logo">
            <img src="logo.svg" alt="" />
        </div>
  
        <div className="texto">
            <h1>
              Queremos revolucionar a forma
              de trabalhar online.
            </h1>
  
            <h3>
            Existem varias maneiras de trabalhar online, e te ajudamos a começar do zero e potencializar seu negócio.
            </h3>
  
            <h5>
            Preencha o formulário para confirmar sua inscrição e ficar ligado no lançamento da Zuppi 
            💜🚀
            </h5>
  
        </div>
  
          <form onSubmit={createUser}>
              <input type="email" onChange={e => this.email = e.target.value} placeholder='Insira seu melhor e-mail' required />
              <br /><br />
              <input type="tel" onChange={e => this.celular = e.target.value} placeholder="(99) 9999-9999" />
              <br /><br />
              <button type='submit'>Enviar</button>
          </form>
          <br /><br />
          </div>
  
          <div className="col-lg-6">
              <div className="imagem">
                <img src="foguete.png" alt="" />
              </div>
          </div>
  
        </div>
  
  
      </div>
    );

    }
  }

export default Register;