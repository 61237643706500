import React, { SyntheticEvent, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Register from './pages/Register';
import Obrigado from './pages/Obrigado';

function App() {

  return (
    <div className='App'>
        <BrowserRouter>
          <Routes>
            <Route path={'/'} element={<Register />} />
            <Route path={'/obrigado'} element={<Obrigado />} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
